section.policy {
    padding: 20px 80px;
    user-select: none;
}
.legales{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin: ptToRem(40);

  span{
    color: var(--color-red);
  }

  h1{
      font-weight: 700;
      font-size: 2rem;
      line-height: 1;
      text-align: justify;
      color: var(--color-black);
      margin: 30px 0;
      padding: 0;
  }

  h2{
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 1;
      text-align: justify;
      color: var(--color-black);
      margin: 20px 0;
      padding: 0;
  }

  p{
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 1.1;
      padding: 0;
      margin: 0 0 0 10px;
  }

  a{
    padding: 0 4px;
    color: var(--color-black);
    background-color: var(--color-grey);
    text-decoration: none;
    transition-duration: 0.35s;

    &:hover{
        color: var(--color-red);
        background-color: transparent;
    }
  }
}