@import '../../assets/scss/mixins.scss';

.team-container{
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;

  @include phone {
    padding: 20px;
  }

  .team-member{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 80%;
    height: auto;
    margin: 10px 0;
    border: 1px solid var(--color-grey);
    
    @include phone {
      flex-direction: column;
      width: 100%;
    }

    .team-member-left{
      width: 60%;
      height: 100%;

      @include phone {
        width: 100%;
      }

      h1{
        font-weight: var(--font-bold);
        font-size: 1.4rem;
        color: var(--color-red);
        padding: 10px 30px;

        @include phone {
          font-size: 1.2rem;
        }
      }

      p{
        font-size: 1.15rem;
        font-weight: var(--font-regular);
        color: var(--color-grey-dark);
        padding: 0 30px;

        @include phone {
          font-size: 1.1rem;
        }

        span{
          font-weight: var(--font-bold);
        }
      }
    }

    .team-member-right{
      position: relative;
      width: 40%;

      @include phone {
        width: 0;
        display: none;
        visibility: hidden;
      }

      img{
        border: 0 none;
        width: 100%;
      }
    }
  }
}