@import '../../assets/scss/mixins.scss';

header{
   position: relative;
   height: 100px;

   @include phone{
      height: auto;
   }

   .navigation {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     height: 100%;

     @include phone {
         flex-direction: column;
         justify-content: center;
         align-items: flex-start;
     }
     
     .logo{ 
           margin-left: 40px;

           @include phone {
            margin: 0;
            padding: 20px 30px;
            width: 100%;
           }

           svg{
              width: 120px; // logo size.

              @include phone {
               height: 80px;
               width: auto;
              }
          }
     }
  
     nav{
           margin-right: 120px;

           @include phone {
            margin-right: 0;
           }
  
           ul{
              display: flex;
              align-items: center;
 
              li{
                 list-style: none;
                 margin-left: 16px;
                 @include phone {
                  margin-left: 23px;
                 }
                 
                 a{
                       cursor: pointer;
                       font-size: 1.3rem;
                       font-weight: 600;
                       color: var(--color-black);
                       padding-bottom: 6px;
                       text-decoration: none;
                       border-bottom: 2px solid none;
                       transition-duration: 0.3s;

                       @include phone{
                        font-size: 1.1rem;
                       }
                       
                       &:hover{
                          padding-bottom: 2px;
                          border-bottom: 2px solid var(--color-grey);

                          @include phone{
                           padding-bottom: 0 none;
                           border-bottom: 0 none;
                          }
                       }
                 }

                 a.current {
                       padding-bottom: 2px;
                       border-bottom: 2px solid var(--color-red);
                 }
              }
           }
     }
  }
}