@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;700&display=swap');

:root {
  --color-white: #ffffff; 
  --color-black: #242424; 
  --color-grey:  #E6E6E6; 
  --color-grey-dark:  #898686; 
  --color-red:   #EF4636; 
  --color-pink:  #FCEDF2;

  --font-regular: 400;
  --font-bold: 700;
}

html {
  font-family: 'EB Garamond', serif;
  font-weight: var(--font-regular);
  background-color: var(--color-white);
  font-size: 14px;
}

body{
  margin: 0;
  padding: 0;
}

code{
  margin: 20px;
}