@import '../../assets/scss/mixins.scss';

section.about-illustration{
  position: relative;
  height: calc(100vh - 160px); // 170px = header + footer
  width: 100%;
  background-image: url(../../assets/jpg/jay_illu_home.jpg);
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: 0 center;
  background-color: var(--color-white);

  @include phone { 
    height: calc(100vh - 230px);
    background-size: 150%;
    background-position:  bottom left;
 }

  .about{
    position: absolute;
    width: 60%;
    bottom:25%;
    right: 12%;

    @include phone { 
      width: 80%;
      top:7%;
      right: 10%;
   }

    h3{
      font-weight: 400;
      font-size: 1.3rem;
      text-align: justify;
      color: var(--color-black);
      margin: 0 0 30px 0;
      padding: 0;

      @include phone {    
        font-size: 1rem; 
     }

      span{
          border-bottom: 2 solid var(--color-red);
          padding-bottom: 2px;
      }
    }
  }
}