@import '../../assets/scss/mixins.scss';

footer {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: transparent;

  @include phone {
    height: auto;
  }

  p{
      margin-left: 30px;
      font-size: 0.85rem;
      font-weight: var(--font-regular);
      color: var(--color-red);

      @include phone {
        margin-left: auto;
        margin-right: auto;
        font-size: 0.7rem;
      }

      a{
          color: inherit;
          text-decoration: underline;
      }

      span{
          color: var(--color-grey-dark);

          a{
              color: inherit;
              text-decoration: underline;
          }
      }
  }
}