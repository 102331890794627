article.card {
    position: relative;
    min-height: 380px;
    width: 310px;
    margin: 0;
    padding: 0;
    border: 1px solid var(--color-grey);
    background-color: var(--color-white);
    
    .image-slide{
        position: relative;
        width: 100%;
        height: 190px;
        border-bottom: 1px solid var(--color-grey);
        background-color: var(--color-grey);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        overflow: hidden;

        .image-legend{
            padding: 3px 10px;
            display: block;
            font-size: var(--font-regular);
            color: var(--color-red);
            font-size: 0.6rem;
        }
    }

    .swiper-pagination-bullet{
        background: var(--color-red)!important;
        opacity: 0.3;
    }
    .swiper-pagination-bullet-active{
        opacity: 1;
        background: var(--color-red)!important;
    }

    .card-info{
        position: relative;
        padding: 5px 20px;

        span.line{
            display: block;
            width: 50%;
            height: 1px;
            background-color: var(--color-red);
            margin: 0 auto;
            padding: 0;
        }

        h1{
            font-size: 1.15rem;
            font-weight: var(--font-bold);
            color: var(--color-red);
            text-align: center;
        }
    
        h2{
            font-size: 1rem;
            font-weight: var(--font-regular);
            text-align: center;
            color: var(--color-black);
        }

        ul{
            margin: 10px 0;

            list-style:none;
            li::before {
                display: inline-block; 
                content: "•"; 
                color: var(--color-red);
                width: 0.85rem;
                margin-left: -0.85rem;
            }
            
            li{
                color: var(--color-grey-dark);
                font-weight: var(----font-bold);
                font-size: .92rem;
            }
        }

        a{
            display: block;
            font-size: 0.85rem;
            text-decoration: none;
            color: var(--color-red);
            transition-duration: 0.35s;

            &:hover{
                color: var(--color-black);
            }
        }
    }
}