@import '../../assets/scss/mixins.scss';

section.contact{
  user-select: none;
  height: calc(100vh - 160px); // 170px = header + footer
  
  .contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

    h3{
        max-width: 900px;
        font-weight: 400;
        font-size: ptToRem(30);
        line-height: ptToRem(44);
        text-align: justify;
        color: var(--color-black);
        margin: ptToRem(20);
        

        span{
            border-bottom: 1 var(--color-red) solid;
            padding-bottom: 4px;
        }
    }

    .contact-bottom{
        display: flex;
        justify-content: center;
        align-items: flex-end;

        @include phone{
          width: 100%;
        }

        .contact-bottom-left{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            background-image: url('../../assets/jpg/jay_illu_contact.jpg');
            background-repeat: no-repeat;
            background-size: 80%;
            background-position: top right;
            margin-right: 20px;
            height: 65vh;
            min-width: 25%;

            @include phone {
              justify-content: flex-start;
              background-size: contain;
              background-position: right top;
              margin-right: 0;
              min-width: 100%;
              width: 100%;
              padding-left: 60px;
            }

            p{
                font-size: 1.15rem;
                font-weight: 400;
                color: var(--color-black);
                line-height: 1;
                margin: 3px 0;
                padding: 0;
            }

            a.contact-map{
                font-weight: 400;
                color: var(--color-black);
                font-size: ptToRem(20);
                line-height: ptToRem(20);
                padding-top: 20px;
                text-decoration: none;
                border-bottom: 2px var(--color-red) solid;
                transition-duration: 0.3s;

                &:hover{
                    border-bottom: 2px var(--color-grey-dark) solid;
                }
            }

            h5{
                margin-top: 10px;
                font-weight: var(--font-regular);
                color: ar(--color-black);
                font-size: 1.2rem;
                text-decoration: none;
            }

            a{
                margin: 0;
                font-weight: 400;
                color: var(--color-red);
                font-size: 1rem;
                padding-bottom: 10px;
                text-decoration: none;
                border-bottom: 2px var(--color-white) solid;
                transition-duration: 0.3s;

                &:hover{
                    border-bottom: 2px var(--color-grey-dark) solid;
                }
            }
        }

        .contact-bottom-right {
            width: 40%;

            @include phone {
              width: 0;
              display: none;
              visibility: none;
            }

            a{
              text-decoration: none;

              img{
                width: 100%;
                border: 1px solid transparent;
                transition-timing-function: ease-in;
                transition-duration: 0.15s;

                &:hover{
                    border: 1px solid var(--color-red);
                }
            }
            }
        }
    }
  }
}